body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#section-main {
    /*background-image: url("../images/pvbeach.png");*/

}

h1 {
    color: rgba(70, 70, 70, 1);
}

.flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.flex-item {
    display: block;
    margin: 40px;
}

.flex-item img {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    
    .flex-item {
        display: block;
        margin: 2vh;
    }
}
.App {
  text-align: center;
}

/*.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

#menu {
  z-index: 9;
  position: fixed;
  width: 100%;
  background-color: none;
  padding: 10px;
  margin: 0px;
  text-align: right;
  right: 4vw;
  top: 20px;
}

#menu li {
  display: inline;
  padding: 10px;
}

#menu a {
  text-decoration: none;
  font-size: 1.2em;
  font-family: 'Montserrat', 'sans-serif';
  color: rgba(70, 70, 70, 1);
  padding-right: 40px;
}

#logo {
  z-index: 10;
  position: fixed;
  color: rgba(70, 70, 70, 1);
  background-color: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 400px;
}

#logo .item {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.6em;
  font-family: 'Montserrat', 'sans-serif';
}

#logo img {
  width: 30%;
  
}

@media only screen and (max-width: 600px) {
  .hide {
    display: none;
  }
}

